'use client';

import React, {ReactNode} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import style from "./BlogSliderSection.module.sass";
import {Navigation} from "swiper/modules";

export function BlogSliderSectionSwiper({children}: {
  children: [number, ReactNode][],
}) {
  // todo: create a common wrapper
  return <Swiper className={style['cards']} slidesPerView='auto' spaceBetween='0' centeredSlides={false}
                 centeredSlidesBounds={false} modules={[Navigation]}
                 navigation={{prevEl: '#prev-blog-slider', nextEl: '#next-blog-slider'}}>
    {children.map(c => <SwiperSlide key={c[0]} className={style['card']}>
      {c[1]}
    </SwiperSlide>)}
  </Swiper>
}
