import {languageCodes} from "../../translations/translations";
import {OpeningTimeDay, OpeningTimes} from "@/utils/directus.types.ts";

export interface TiqetsImageData {
  credits?: string
  alt_text?: string
  large?: string
  medium?: string
  small?: string
}

export interface TiqetsProductData {
  audio_guide_languages: languageCodes[] | null;
  live_guide_languages: languageCodes[] | null;
  instant_ticket_delivery: boolean;
  wheelchair_access: boolean;
  smartphone_ticket: boolean;
  cancellation: {
    policy: string,
    window: string,
  }
  product_checkout_url: string
  duration: string
  price: number
  currency: string
  skip_line: boolean
  prediscount_price: number
  discount_percentage: number
  ratings: {
    total: number,
    average: number,
  }
  images: TiqetsImageData[]
  geolocation?: {
    lat: number
    lng: number
  } | null
  starting_point?: {
    lat: number
    lng: number
    label: string | null
    address: string | null
    city_id: number
  } | null
}

export function tiqetsImage(image: TiqetsImageData, size: keyof TiqetsImageData, width: number, height: number) {
  let link = image[size];
  if (!link) {
    return '';
  }
  const maybeImgixLink = new URL(link);
  maybeImgixLink.searchParams.delete('w');
  maybeImgixLink.searchParams.delete('h');
  maybeImgixLink.searchParams.delete('fit');
  maybeImgixLink.searchParams.append('w', width.toString());
  maybeImgixLink.searchParams.append('h', height.toString());
  maybeImgixLink.searchParams.append('fit', 'crop');
  return maybeImgixLink.toString();
}

export function tiqetsImageCredits(image: TiqetsImageData, fallback: string | null | undefined) {
  let by = image.credits ? ` (by ${image.credits})` : '';
  return `${image.alt_text || fallback}${by}`;
}

export interface DerivedSchedule {
  timezone: string,
  title: string,
  week: OpeningTimeDay[]
}

export function tiqetsFixProductCheckoutUrl(data: TiqetsProductData, locale: string) {
  const productAffiliateLink = new URL(data.product_checkout_url);
  productAffiliateLink.pathname = productAffiliateLink.pathname.replaceAll(/^\/\w+\//g, `/${locale}/`);
  productAffiliateLink.searchParams.delete('partner');
  productAffiliateLink.searchParams.append('partner', 'scroll_and_go');
  return productAffiliateLink.toString();
}

export function openingDaysToSchedules(openingTimes: OpeningTimes[]): DerivedSchedule[] {
  openingTimes = openingTimes.filter(d => !!d.openings.length);
  if (!openingTimes.length) {
    return []
  }
  return openingTimes.map(ot => ({
    timezone: ot.openings[0].timezone,
    title: ot.entity_name,
    week: ot.openings.reduce((list: OpeningTimeDay[], d) => {
      const date = new Date(d.date)
      list[date.getDay()] = d
      return list
    }, [])
  }))
}
