import {ReactNode} from "react";

import style from "./FlexSection.module.sass"

export function FlexSection({children, className}: {children: ReactNode, className?: string}) {
  return <section className={[style['section'], className].join(" ")}>
    <div className={style['content']}>
      {children}
    </div>
  </section>
}
