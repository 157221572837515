'use client'

import {MoreButton} from "@/components/buttons/MoreButton.tsx";
import {Attractions} from "@/utils/directus.types.ts";
import {useTranslations} from "next-intl";
import React, {ReactNode, useState} from "react";
import {useEntityTranslator} from "@/utils/i18n-helpers.ts";
import {Link} from "@/navigation.ts";
import {attractionLink} from "@/utils/links.ts";
import {directusImageAuto, takeCities} from "@/utils/directus.ts";
import {ButtonData, SectionFilterButtons} from "@/components/contentSections/SectionFilterButtons.tsx";
import {FlexSubSection} from "@/components/contentSections/FlexSubSection.tsx";
import {FlexSection} from "@/components/contentSections/FlexSection.tsx";
import {FlexHeaderSection} from "@/components/contentSections/FlexHeaderSection.tsx";
import {formatCurrency} from "@/i18n.tsx";
import NoPhoto from "@/components/placeholders/NoPhoto.tsx";
import style from "./AttractionListSection.module.sass";
import sliderStyle from "./AttractionSliderSection.module.sass";
import {tiqetsImageCredits} from "@/utils/tiqets.ts";
import unfortunate from "@/styles/unfortunate.module.sass";

function AttractionItem({attraction}: { attraction: Attractions }) {
  const t = useTranslations()
  const et = useEntityTranslator()
  return <article>
    <Link href={attractionLink(attraction)} title={et(attraction, 'title')} className={unfortunate['cardItem']}>
      <div className={style['listItem']}>
        {attraction.preview_image
          ? <img
            className={unfortunate['poster']}
            src={directusImageAuto(attraction.preview_image.image, 265, 265)}
            alt={et(attraction.preview_image, 'alt')}
            title={et(attraction.preview_image, 'title')}
          />
          : attraction.tiqets_images?.[0]?.medium
            ? <img
              className={unfortunate['poster']}
              src={attraction.tiqets_images?.[0]?.medium}
              alt={tiqetsImageCredits(attraction.tiqets_images?.[0], et(attraction, 'title'))}
              title={et(attraction, 'title')}
            />
            : <NoPhoto/>}
      </div>
      <div className={sliderStyle.text}>
        <div className={sliderStyle.title}>
          {et(attraction, 'title')}
        </div>
        {!!attraction.min_price && !!+attraction.min_price && <div className={sliderStyle.price}>
          {t('sections.attractions.priceFrom', {price: +attraction.min_price}, formatCurrency('EUR'))}
        </div>}
      </div>
    </Link>
  </article>
}

function lexicalSort(a: string, b: string) {
  return +(a < b) - +(a > b)
}

export const AttractionListSection = ({attractions, filter, multiplier, header, title, className}: {
  attractions: Attractions[],
  filter?: boolean,
  multiplier?: number,
  header?: ReactNode,
  title?: string,
  className?: string,
}) => {
  const showMultiplier = multiplier || 8;
  const t = useTranslations()
  const [showMore, setShowMore] = useState(0)
  const onToggle = () => {
    setShowMore(showMore + showMultiplier)
  };
  const filterOptions = (filter ? takeCities(attractions) : []).sort((a, b) => lexicalSort(b.slug, a.slug));
  const [filteredBy, setFilteredBy] = useState<ButtonData>(filterOptions[0]);
  const onFilter = (filterBy: ButtonData) => {
    setShowMore(0)
    setFilteredBy(filterBy)
  }
  const filteredItems = filter ? attractions.filter(a => a.city.id === filteredBy.id) : attractions;
  return <FlexSection className={className}>
    {header ? header : <FlexHeaderSection>
      {title || t('sections.attractions.header')}
    </FlexHeaderSection>}
    {(filter ?? false) && <SectionFilterButtons options={filterOptions} selected={filteredBy} onSelect={onFilter}/>}
    <FlexSubSection>
      <div className={style['attractionListGrid']}>
        {filteredItems.slice(0, showMultiplier + showMore)
          .map(attraction => <AttractionItem attraction={attraction} key={attraction.id}/>)}
      </div>
      {(filteredItems.length > showMultiplier + showMore) && <MoreButton opened={false} onClick={onToggle}/>}
    </FlexSubSection>
  </FlexSection>
}
