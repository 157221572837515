import {ReactNode} from "react";
import style from "./FlexHeaderSection.module.sass";
import {FlexSubSection} from "@/components/contentSections/FlexSubSection.tsx";
import {classList} from "@/utils/lib.ts";

export function FlexHeaderSection({children, center}: { children: ReactNode, center?: boolean }) {
  return <FlexSubSection>
    <h2 className={classList(style['header'], center && style['preferCentered'])}>
      {children}
    </h2>
  </FlexSubSection>
}
