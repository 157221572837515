import {useTranslations} from "next-intl";
import style from "./NoPhoto.module.sass";

export default function NoPhoto({className}: {className?: string}) {
  const t = useTranslations()
  return <div className={`${className} ${style['container']}`}>
    <span className={style['text']}>
      {t("no_photo_yet")}
    </span>
  </div>
}