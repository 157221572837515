import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {useTranslations} from "next-intl";
import style from "./MoreButton.module.sass";
import {classList} from "@/utils/lib.ts";

export const MoreButton = ({opened, onClick}: { opened: boolean, onClick: () => void }) => {
  const t = useTranslations()
  return <div className={style['container']}>
    <button onClick={onClick} className={style['button']}>
      {opened ? t('sections.showLess') : t('sections.showMore')}
      <ChevronDownIcon className={classList(style['chevron'], opened && style['chevronOpen'])}/>
    </button>
  </div>
}
